<template>
  <div class="content">

    <div class="main-screen-section inside screen3">
    </div>

    <div class="container inside-page content-section">
      <h1>Клинические проявления мигрени</h1>
      <div class="content-text text-size-big mb40">
        <p>
          Мигрень — это сложный процесс в головном мозге, который может длиться от <br> 4 часов до 3 дней. Выделяют четыре
          различные фазы:
        </p>
      </div>

      <table class="manifestations">
        <thead>
        <tr>
          <td>Продром</td>
          <td>Аура</td>
          <td>Головная боль</td>
          <td>Постдром</td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <p>Сталкивается > 80% больных</p>
            <p>Начинается за 1-24 часа до головной боли</p>
          </td>
          <td>
            <p>Сталкивается 25% больных</p>
            <p>5-60 минут</p>
          </td>
          <td>
            <p>Сталиваются все больные</p>
            <p>от 4 часов до 3 дней</p>
          </td>
          <td>
            <p>Сталкивается 70% больных</p>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div class="sub-title">Симптомы</div>
          </td>
        </tr>
        <tr>
          <td>
            <p>Тошнота;</p>
            <p>Рвота;</p>
            <p>Зевота;</p>
            <p>Озноб;</p>
            <p>Диарея;</p>
            <p>Потеря аппетита;</p>
            <p>Усталость;</p>
            <p>Дурнота;</p>
            <p>Депрессия;</p>
            <p>Раздражительность;</p>
            <p>Задержка жидкости;</p>
            <p>Повышенное мочеиспускание;</p>
            <p>Заторможенность;</p>
            <p>Чувствительность <br> к свету и звуку;</p>
            <p>Повышенная утомляемость;</p>
            <p>Гиперактивность;</p>
            <p>Мышечно-скелетные симптомы.</p>
          </td>
          <td>
            <p>Мерцающая скотома;</p>
            <p>Онемение;</p>
            <p>Покалывание;</p>
            <p>Слабость;</p>
            <p>Временный паралич;</p>
            <p>Головокружение;</p>
            <p>Временная утрата способности говорить, читать или писать.</p>
          </td>
          <td>
            <p>Интенсивная пульсирующая односторонняя головная боль;</p>
            <p>Тошнота;</p>
            <p>Рвота;</p>
            <p>Фонофобия;</p>
            <p>Фотофобия.</p>
          </td>
          <td>
            <p>Ощущение «похмелья»;</p>
            <p> Слабость;</p>
            <p>Истощение;</p>
            <p>Когнитивные нарушения;</p>
            <p>Перепады настроения;</p>
            <p>Симптомы ЖКТ;</p>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="content-text text-size-middle mb50">
        <p>
          У пациентов не всегда наблюдаются все 4 фазы во время каждого приступа, также фазы могут перекликаться между
          собой. Все 4 фазы усиливают дистресс и усугубляют степень инвалидизации пациента.
        </p>
      </div>

      <h2 class="mb20">Классификация мигрени</h2>
      <h3>Мигрень с аурой и без ауры</h3>

      <div class="content-text text-size-small mb40">
        <p>С аурой (25% больных) — характеризуется появлением обратимых неврологических симптомов, включая зрительные,
          чувствительные, речевые и/или языковые или двигательные симптомы. Также могут наблюдаться функциональные
          нарушения со стороны ствола головного мозга или мышечная слабость.
        </p>
        <p>
          Без ауры (75% больных) — характерен переход от продрома к фазе головной боли при отсутствии симптомов ауры.
        </p>
      </div>

      <div class="episodic">
        <div class="title">Эпизодическая и хроническая мигрень
        </div>
        <p>Эпизодическая (ЭМ) — меньше 15 дней с головной болью/месяц. <br>Хроническая (ХМ) — больше 15 дней с головной
          болью/месяц.</p>
        <p> Эпизодическая мигрень может перейти в хроническую форму; ежегодно такое <br> прогрессирование отмечается
          примерно у 2,5% пациентов.</p>
      </div>

      <h4>Факторы риска трансформации эпизодической мигрени в хроническую</h4>

      <div class="content-text text-size-middle">

        <div class="row">
          <div class="col-md-5">
            <div class="list-content">
              <div class="sub-list-title"><i>Немодифицируемые факторы риска</i></div>
              <ul class="list-block">
                <li>  Пожилой возраст;</li>
                <li>  Женский пол;</li>
                <li>  Европеоидная раса;</li>
                <li>  Низкий уровеньобразованности;</li>
                <li>  Низкий социально-экономический статус;</li>
                <li>  Хроническая мигрень в семейном анамнезе.</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="list-content">
              <div class="sub-list-title"><i>Модифицируемые факторы риска</i></div>
              <ul class="list-block">
                <li> Избыточное потребление кофеина;</li>
                <li>  Ожирение;</li>
                <li>  Храп и апноэ во сне;</li>
                <li>  Избыточное применение симптоматических лекарственных препаратов;</li>
                <li>  Психические заболевания и стрессовые события в жизни;</li>
                <li>  Приступы мигрени чаще 4 дней в месяц.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'ClinicalManifestations',
  metaInfo: {
    title: 'Клинические проявления',
    meta: [
      { name: 'description', content: 'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность' }
    ]
  }
}
</script>

<style scoped>
@media (min-width: 320px) and (max-width: 767px) {
.content-text p br {
   display: none;
}
}
</style>
